import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import BestArticles from 'components/pages/blog/best-articles';
import AdditionalLinks from 'components/pages/blog/additional-links';
import Subscribe from 'components/pages/blog/subscribe';
import Banner from 'components/pages/blog/banner';
import BlogPostCard from 'components/shared/blog-post-card';

import config from './config';

import shape1 from './svg/shape-1.svg';
import shape2 from './svg/shape-2.svg';

import styles from './grid.module.scss';

const cx = classNames.bind(styles);

const Grid = (props) => {
  const {
    items,
    additionalLinksTitle,
    additionalLinksItems,
    subscribeBannerTitle,
    subscribeBannerInputPlaceholder,
    subscribeBannerButtonText,
    imageBannerImage,
    imageBannerLink,
    imageBannerImageAltText,
    bestArticlesBannerTitle,
    bestArticlesBannerPosts,
  } = props;

  return (
    <div className={cx('wrapper')}>
      <div className={cx('container', 'inner')}>
        {config.map(({ columns, component, ...componentProps }, index) => {
          const columnClassName = cx('column', `column-${columns}`);

          if (component === 'card') {
            const itemIndex = config.reduce((articleIndex, { component }, innerIndex) => {
              if (innerIndex > index) return articleIndex;
              if (component === 'card' && innerIndex !== 0) return articleIndex + 1;
              return articleIndex;
            }, 0);

            const item = items[itemIndex];
            if (component === 'card' && !item) return null;

            const { size } = componentProps;
            const imageSize = size ? `${size}Image` : 'defaultImage';

            return (
              <BlogPostCard
                className={columnClassName}
                title={item.title}
                description={item.acf.description}
                categories={item.categories.nodes}
                image={item.acf[imageSize]?.localFile?.childImageSharp}
                url={item.uri}
                key={index}
                {...componentProps}
              />
            );
          }

          if (component === 'additionalLinks')
            return (
              <AdditionalLinks
                title={additionalLinksTitle}
                items={additionalLinksItems}
                key={index}
              />
            );

          if (component === 'subscribe') {
            if (items.length < 4) return null;

            const className = cx(columnClassName, 'subscribe');
            return (
              <Subscribe
                className={className}
                title={subscribeBannerTitle}
                inputPlaceholder={subscribeBannerInputPlaceholder}
                buttonText={subscribeBannerButtonText}
                key={index}
              />
            );
          }

          if (component === 'bestArticles') {
            if (items.length < 5) return null;

            return (
              <BestArticles
                className={columnClassName}
                title={bestArticlesBannerTitle}
                items={bestArticlesBannerPosts}
                key={index}
              />
            );
          }

          if (component === 'banner') {
            if (items.length < 7) return null;

            const className = cx(columnClassName, 'banner');
            return (
              <Banner
                className={className}
                image={imageBannerImage}
                link={imageBannerLink}
                altText={imageBannerImageAltText}
                key={index}
              />
            );
          }

          return null;
        })}

        <img className={cx('shape-1')} src={shape1} loading="lazy" alt="" aria-hidden />
        <img className={cx('shape-2')} src={shape2} loading="lazy" alt="" aria-hidden />
      </div>
    </div>
  );
};

Grid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  additionalLinksTitle: PropTypes.string.isRequired,
  additionalLinksItems: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  subscribeBannerTitle: PropTypes.string.isRequired,
  subscribeBannerInputPlaceholder: PropTypes.string.isRequired,
  subscribeBannerButtonText: PropTypes.string.isRequired,
  imageBannerImage: PropTypes.objectOf(PropTypes.any).isRequired,
  imageBannerLink: PropTypes.string.isRequired,
  imageBannerImageAltText: PropTypes.string.isRequired,
  bestArticlesBannerTitle: PropTypes.string.isRequired,
  bestArticlesBannerPosts: PropTypes.arrayOf(
    PropTypes.shape({ post: PropTypes.objectOf(PropTypes.any) })
  ).isRequired,
};

export default Grid;
