import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import BlogPostCard from 'components/shared/blog-post-card';

import config from './config';

import styles from './read-more.module.scss';

const cx = classNames.bind(styles);

const ReadMore = ({ items }) => (
  <section className={cx('wrapper')}>
    <div className="container">
      <Heading className={cx('title')} tag="h4">
        Read more
      </Heading>

      <div className={cx('items-wrapper')}>
        <div className={cx('items')}>
          {config.map(({ columns, component, ...componentProps }, index) => {
            const className = cx('column', `column-${columns}`);
            const item = items[index].post;

            const { size } = componentProps;
            const imageSize = size ? `${size}Image` : 'defaultImage';
            if (!item) return null;
            return (
              <BlogPostCard
                className={className}
                title={item.title}
                description={item.acf.description}
                categories={item.categories.nodes}
                image={item.acf[imageSize]?.localFile?.childImageSharp}
                url={item.uri}
                {...componentProps}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  </section>
);

ReadMore.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      post: PropTypes.objectOf(PropTypes.any),
    })
  ).isRequired,
};

export default ReadMore;
